import React from "react";
import { Link, graphql } from "gatsby";
// import Img from "gatsby-image";
import { Gallery, GridGallery } from "../components/gallery";
// import SimilarPlans from "../components/similar-plans";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import { FaBed, FaBath, FaWarehouse, FaFileAlt } from 'react-icons/fa';

class PlanTemplate extends React.Component {
  render(){
    const plan = this.props.data.contentfulPlan;
    const siteTitle = 'The ' + plan.name;
    // const siteTitle = this.props.data.site.Metadata.title;
    // const {previous, next} = this.props.pageContext;

    // let simPlans = SimilarPlans(plan.type);

    const totalSqft = plan.mainSqft + (plan.basementSqft ? plan.basementSqft : 0) + (plan.upstairsSqft ? plan.upstairsSqft : 0);
    const finishedSqft = plan.mainSqft + (plan.upstairsSqft ? plan.upstairsSqft : 0);

    return (
      <Layout location={this.props.location} title={siteTitle}>

        <SEO title={siteTitle} description={plan.description} />

          <Container className="mb-4">
            <Row>
              <Col sm={4}>

                  <h1 className="mt-4 mb-4">The {plan.name}</h1>

                    <Row>
                      <Col>
                        <div className="plan-info">
                          {plan.beds} Bedrooms
                          <FaBed className="ml-2" />
                        </div>
                        <div className="plan-info">
                          {plan.baths} Bathrooms
                          <FaBath className="ml-2" />
                        </div>
                      </Col>
                      <Col>
                        <div className="plan-info">
                          {plan.garage} Car Garage
                          <FaWarehouse className="ml-2" />
                        </div>

                        {plan.bonus ? (
                          <div className="plan-info">
                            Bonus Room
                          </div>
                        ) : null}
                      </Col>
                    </Row>


                  <Table bordered className="mt-4">
                    <tbody>

                      <tr>
                        <td>Main level</td>
                        <td>{plan.mainSqft} sqft</td>
                      </tr>

                      {plan.upstairsSqft > 0 ? (
                        <tr>
                          <td>Upstairs</td>
                          <td>{plan.upstairsSqft} sqft</td>
                        </tr>
                      ) : null}

                      {plan.basementSqft > 0 ? (
                        <tr>
                          <td>Basement</td>
                          <td>{plan.basementSqft} sqft</td>
                        </tr>
                      ) : null}

                      <tr>
                        <td>Total</td>
                        <td>{totalSqft} sqft</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Finished</td>
                        <td className="font-weight-bold">{finishedSqft} sqft</td>
                      </tr>

                    </tbody>
                  </Table>

                  <Row>
                    <Col>
                      <p style={{fontSize: '0.9em'}}><i>We are truly custom home builders. You can use one of our plans as-is or you can work with us to change and modify them as you need!</i></p>
                    </Col>
                  </Row>

                  <Row>

                    <Col className="text-center">
                      <Link to="/contact-us"><button type="button" className="btn btn-success btn-lg mt-3">REQUEST MORE INFO</button></Link>
                    </Col>

                    {plan.brochure && plan.brochure.file && plan.brochure.file.url ? (
                      <Col className="text-center">
                        <a href={plan.brochure.file.url} target="_blank" rel="noopener noreferrer"><button type="button" className="btn btn-success btn-lg mt-3">BROCHURE <FaFileAlt /></button></a>
                      </Col>
                    ) : null}

                  </Row>


              </Col>
              <Col sm={8}>
                <Gallery images={plan.images} gid="main"/>
              </Col>
            </Row>


            {plan.gallery && plan.gallery.length > 0 ? (
              <div>
                <hr className="mt-4" />
                <Row className="mt-4">
                  <Col>
                    <h2>{plan.name} Gallery</h2>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <GridGallery images={plan.gallery} gid="planGrid"/>
                  </Col>
                </Row>
              </div>
            ) : null}

            {/*
              <Row className="mt-5">
                <Col>
                  { simPlans }
                </Col>
              </Row>
            */
            }
          </Container>

      </Layout>
    )
  }
};

export default PlanTemplate;

export const pageQuery = graphql`
query PlanBySlug($slug: String!) {
  site {
    siteMetadata {
      title
      author
    }
  }
  contentfulPlan( slug: { eq: $slug }) {
    id
    name
    slug
    beds
    baths
    garage
    mainSqft
    basementSqft
    upstairsSqft
    bonus
    type
    brochure {
      file {
        url
      }
    }
    images {
      fluid {
        ...GatsbyContentfulFluid
      }
      file {
        url
        fileName
        contentType
      }
    }
    gallery {
      fluid {
        ...GatsbyContentfulFluid
      }
      file {
        url
        fileName
        contentType
      }
    }
  }
}`
